import CaseStudyBlade from "components/common/CaseStudyBlade";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import videoSrc from "../../videos/access-control-lp-video-min.mp4";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import FourColumnFeatures from "components/common/FourColumnFeatures";
import IconSystem from "components/common/icon-components/IconSystem";
import IconAiAnalaytics from "components/common/icon-components/IconAiAnalaytics";
import IconSecure from "components/common/icon-components/IconSecure";
import LocationIcon from "components/common/icon-components/LocationIcon";

export default function accesControlD() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        title: "Open Sesame? Nah, Just Use Rhombus!",
        paragraph:
          "Partner with the #1 rated physical security platform to simplify building security with remote management, real-time alerts, and unlimited scalability.<br><b>Get custom pricing and demo from a Rhombus expert today!</b>",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
      industryLogos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
      infoSlider: {
        title: "Everything You Need for Access Control",
        list: [
          {
            tabTitle: "Seamless Entry",
            title: "Easy & Secure Door Access",
            description:
              "Gain instant access to buildings with a wave of your hand or the touch of a keycard. Rhombus Door Reader’s fast and reliable performance ensures smooth access for guests and employees.",
            image: data.benefit1,
          },
          {
            tabTitle: "Remote Control",
            title: "Streamline Visitor Management",
            description:
              "Get maximum visibility on arrivals and departures by pairing access events with live footage. Get notified when visitors arrive, see who’s at the door, and talk to guests all from one system.",
            image: data.benefit2,
          },
          {
            tabTitle: "Emergency Ready",
            title: "Lockdown Spaces in Seconds",
            description:
              "Ensure rapid response and safeguard your spaces by activating Lockdown in just a few clicks or based on the rules you set. Leverage a truly open platform to integrate lockdown with your existing workflows.",
            image: data.benefit3,
          },
          {
            tabTitle: "All-in-One Experience",
            title: "Simplify Security on a Single Platform",
            description:
              "Enhance visibility into your spaces for a streamlined and unified security experience. Accelerate search and investigations with instant visuals on access events with automatic tagging in camera streams.",
            image: data.benefit4,
          },
        ],
      },
      caseStudyBlade: {
        image: data.bladeImage,
        logo: data.caseStudyLogo,
        author: {
          avatar: data.avatar,
          name: "Brad Minsley ",
          role: "Managing Director at 10 Federal",
          review:
            "Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more.",
        },
      },
    };
    return (
      <GlobalLayout
        color="var(--gradient-light)"
        landingPage
        hideFooter
        useCTAFooter
      >
        <Helmet>
          <title>
            Enterprise Cloud-Managed Access Control System | Rhombus
          </title>
          <meta
            name="description"
            content="Unified cloud-managed access control that integrates perfectly with our video, sensors, & alarm monitoring."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout5
          formTitle="See it to believe it. Request a Demo."
          buttonText={CTA_BUTTON_COPY}
          title={pageData.headerSection.title}
          formId={pageData.headerSection.formId}
          blurb={pageData.headerSection.paragraph}
          useHTMLInjection
          video={{
            src: videoSrc,
            aspectRatio: 1,
          }}
        />
        <IndustryFeaturedUpdated
          title="Trusted by Industry Leaders"
          logos={pageData.industryLogos}
          color="var(--white)"
        />

        <InfoSlider
          data={pageData.infoSlider.list}
          title={pageData.infoSlider.title}
          color="var(--nuetral-100)"
          useLink
        />
        <FourColumnFeatures
          features={[
            {
              title: "User-Friendly",
              blurb:
                "Rhombus combines consumer-level ease of use with enterprise-grade performance, from admins to users.",
              icon: () => <IconSystem />,
            },
            {
              title: "AI Analytics",
              blurb:
                "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.",
              icon: () => <IconAiAnalaytics />,
            },
            {
              title: "Always Secure",
              blurb:
                "Prioritize security by using the latest technology and ensuring operations during power or network outages.",
              icon: () => <IconSecure />,
            },
            {
              title: "Infinite Scalability",
              blurb:
                "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
              icon: () => (
                <LocationIcon color="var(--blue-500)" width="36" height="52" />
              ),
            },
          ]}
          title="What Sets Rhombus Apart"
          bgColor="var(--white)"
        />
        <TrustedPartnerSection
          preTitle="The Rhombus Difference"
          title="Your Trusted Security Platform"
          blurb="Rhombus Access Control is part of a unified Cloud-Managed Physical Security platform. Future-proof your security needs with innovative products and an open platform, diverse partner ecosystem, and a team of experts to keep your business secure and safe at all times."
          color="var(--nuetral-100)"
          button={false}
        />
        <CaseStudyBlade data={pageData.caseStudyBlade} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/img/access-control-hero-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      mobileHeaderImage: file(
        relativePath: {
          eq: "components/landing-pages/img/access-control-hero-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/landing-pages/search/img/seamless-entry.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/two-way-audio.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/landing-pages/search/img/lockdown.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/brad-minsley.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/common/logos/10-federal-logo-large.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/common/img/case-studies/10-federal-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
